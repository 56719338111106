import React, { memo } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectBase,
  SelectProps as MuiSelectProps,
  Chip as ChipBase,
  styled,
} from '@mui/material'

export interface SelectItemProps {
  name: string
  value: string
}

interface SelectCoreProps {
  grey?: boolean
  noCaps?: boolean
  hiddenLabel?: boolean
  quickSearch?: boolean
  fullWidth?: boolean
}

export interface SelectProps extends MuiSelectProps, SelectCoreProps {
  name: string
  items: SelectItemProps[]
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  textAlign: 'left',
}))

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '1.6rem',
  zIndex: 100,
  transform: 'translate(20px, -1rem) scale(0.8)',
  textShadow:
    '-2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white',
  opacity: 0,
  transition: theme.transitions.create(['opacity']),
  ['&, &.Mui-focused']: {
    color: theme.palette.textGrey,
  },
  ['&.MuiInputLabel-shrink']: {
    ['&, &.Mui-error']: {
      opacity: 1,
    },
    ['&.Mui-error']: {
      color: `${theme.palette.error.main} !important`,
    },
    svg: {
      opacity: 0,
      width: 0,
      path: {
        fill: `${theme.palette.primary.main} !important`,
      },
    },
  },
}))

const StyledSelect = styled(SelectBase, {
  shouldForwardProp: (prop) =>
    prop !== 'grey' &&
    prop !== 'noCaps' &&
    prop !== 'hiddenLabel' &&
    prop !== 'quickSearch',
})<SelectCoreProps>(({ grey, noCaps, hiddenLabel, quickSearch, theme }) => ({
  minWidth: '100px',
  backgroundColor: theme.palette.common.white,
  overflow: 'hidden',
  borderRadius: theme?.shape?.borderRadiusSmall,
  ['.MuiSelect-select']: {
    paddingLeft: '20px',
    ...(grey && {
      backgroundColor: theme.palette.lightGrey,
      ['&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active']:
        {
          '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.lightGrey} inset !important`,
        },
    }),
  },
  ...(noCaps && {
    ['.MuiSelect-select, .MuiMenuItem-root']: {
      textTransform: 'none !important',
    },
  }),
  ...(hiddenLabel && {
    ['.MuiInputLabel-root']: {
      display: 'none',
    },
  }),
}))

const Select = ({
  name,
  label,
  placeholder,
  items,
  grey,
  noCaps,
  hiddenLabel,
  quickSearch,
  fullWidth,
  ...props
}: SelectProps) => {
  let placeholderLabel = ''
  if (placeholder && placeholder.length > 0) {
    placeholderLabel = placeholder
  } else {
    placeholderLabel = name.charAt(0).toUpperCase() + name.slice(1)
  }

  let selectItems: SelectItemProps[] = []
  if (items && items.length > 0) {
    selectItems = items
  }

  return (
    <StyledFormControl fullWidth={fullWidth}>
      {label && <StyledLabel id={`${name}-label`}>{label}</StyledLabel>}
      <StyledSelect
        key={`${name}-select`}
        labelId={`${name}-label`}
        displayEmpty
        grey={grey}
        {...props}
      >
        <MenuItem value="" disabled>
          {placeholderLabel}
        </MenuItem>

        {items.map((item: SelectItemProps) => {
          const keyId: string = (
            item.value !== undefined ? item.value : ''
          ).toString()
          return (
            <MenuItem key={keyId} value={item.value}>
              {item.name}
            </MenuItem>
          )
        })}
      </StyledSelect>
    </StyledFormControl>
  )
}

export default memo(Select)
